html {

  scroll-behavior: smooth !important;
  overflow-x: hidden;


}

.pointer-events-none {
  pointer-events: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/* Global Styles */
body {
    overflow-x: hidden;
    font-family: Montserrat, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #212529;
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
}

/* Example styles */
h1, h2, h3, h4, h5, h6 {
    font-family: Montserrat, sans-serif;
}

p {
    font-family: Montserrat, sans-serif;
}
b {
  font-weight: 600;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
